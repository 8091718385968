<template>
    <v-navigation-drawer v-model="display" absolute temporary>
        <v-list nav dense>
            <v-list-item-group active-class="deep-green--text text--accent-4">
                <v-list-item router :to="'/' +  channelName">
                    <v-list-item-icon>
                        <CustomIcon :size="24" color="#008000" glyph="mdi-home" /> 
                    </v-list-item-icon>
                    <v-list-item-action>
                        <v-list-item-title>Channel Home</v-list-item-title>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item router to="/sys/profile">
                    <v-list-item-icon>
                        <CustomIcon :size="24" color="#008000" glyph="mdi-account" /> 
                    </v-list-item-icon>
                    <v-list-item-action>
                        <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item-action>
                </v-list-item>
                <a :href="'/' +  channelName + '/random'">
                    <v-list-item>
                        <v-list-item-icon>
                            <CustomIcon :size="24" color="#008000" glyph="mdi-format-list-numbered" /> 
                        </v-list-item-icon>
                        <v-list-item-action>
                            <v-list-item-title>New List</v-list-item-title>
                        </v-list-item-action>
                    </v-list-item>
                </a>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
    const one_minute = 60000;

    export default {
        components: {
            CustomIcon: () => import("/src/components/CustomIcon.vue"),
        },
        data() {
            return {
                channelName: null,
                display: false,
            };
        },
        mounted() {
            this.channelName = localStorage.getItem('primaryChannelName');
        },
        methods: {
            showMenu() {
                this.display = true;
            },
        },
    };
</script>
<style scoped>
.v-icon {
    color:green;
}
.v-list-item__title {
    color:green;
}

a {
  color:green;
  text-decoration: none;
}
</style>